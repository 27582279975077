<template>
    <div>
        <div v-if="mode === 'choose'">
            <h4 class="tx-bold tx-white mg-b-20 tx-uppercase tx-center">Please select the type of claim you wish to create</h4>
            <div class="d-flex justify-content-center">
                <button class="btn btn-dark pd-x-20 pd-y-10 tx-uppercase tx-bold tx-spacing-6 tx-24 mg-r-10" @click="mode = 'nmop'">No Means of Payment</button> <button class="btn btn-dark pd-x-20 pd-y-10 tx-uppercase tx-bold mg-l-10 tx-spacing-6 tx-24" @click="mode = 'driveoff'">Drive Off</button>
            </div>
        </div>
        <drive-off-form v-if="mode === 'driveoff'" @mode="changeMode"></drive-off-form>
        <nmop-form v-if="mode === 'nmop'" @mode="changeMode"></nmop-form>
    </div>
</template>

<script>
import DriveOffForm from "./DriveOffForm";
import NmopForm from "./NmopForm";
export default {
    name: "ClaimForm",
    components: {NmopForm, DriveOffForm},
    data(){
        return {
            mode: "choose",
        }
    },
    methods: {
        changeMode(mode){
            this.mode = mode;
        }
    }
}
</script>

<style scoped>

</style>